/* eslint-disable jsx-a11y/accessible-emoji */
import { Typography, Row, Col } from 'antd';
import { NextPage } from 'next';
import Image from './Image';

const { Title, Paragraph } = Typography;

const WhySection: NextPage = () => {
  return (
    <div className="container" style={{ overflow: 'hidden' }}>
      <style jsx>
        {`
          .container {
            background-color: white;
            padding-top: 100px;
            padding-bottom: 100px;
          }
          .first-row {
            margin-bottom: 0px;
          }
          h2 {
            font-size: 38px;
            font-weight: 900;
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
              'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
              'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
              'Noto Color Emoji';
          }
          .title {
            text-align: center;
            margin-bottom: 40px;
          }
        `}
      </style>
      <Row justify="center">
        <Col>
          <div className="title">
            <Title>Career Accelerator</Title>
          </div>
        </Col>
      </Row>
      <Row justify="center">
        <Col xs={20} sm={20} md={12} lg={10}>
          <Row justify="start">
            <Col>
              <Row gutter={[24, 0]} justify="start">
                <Col>
                  <Image width={42} description="" name="scrum.png" />
                </Col>
                <Col>
                  <Title level={3}>Pass the Coding Interview</Title>
                </Col>
              </Row>
              <Paragraph>
                Learn tips from the experts for passing your coding interview
                &amp; achieving a disciplined schedule for continous project
                delivery on the job.
              </Paragraph>
            </Col>
          </Row>
          <Row justify="start">
            <Col>
              <Row gutter={[24, 0]} justify="start">
                <Col>
                  <Image width={42} description="" name="remote.png" />
                </Col>
                <Col>
                  <Title level={3}>Land a Remote Job</Title>
                </Col>
              </Row>
              <Paragraph>
                We have an unmatched network of high paying companies offering
                remote W2 jobs for Software Engineers from coding bootcamps,
                such as Thinkful and Springboard.
              </Paragraph>
            </Col>
            <Col>
              <Row gutter={[24, 0]} justify="start">
                <Col>
                  <Image
                    width={42}
                    description=""
                    name="private-and-public.png"
                  />
                </Col>
                <Col>
                  <Title level={3}>Private &amp; Public Sector</Title>
                </Col>
              </Row>
              <Paragraph>
                We have a network of quality tech recruiters with experience
                placing coding bootcamp graduates at jobs in the private &amp;
                public sector at organizations like Amazon &amp; the CDC.
              </Paragraph>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
export default WhySection;
