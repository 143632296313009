/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
type ImageProps = {
  name: string;
  description: string;
  width?: number;
  height?: number;
};

const Image: React.FunctionComponent<ImageProps> = (props) => {
  const { name, description, width, height } = props;
  let type = '';
  const noRender = name === undefined;
  if (!noRender) {
    if (name.endsWith('.png')) {
      type = 'image/png';
    } else if (name.endsWith('.jpeg') || name.endsWith('.jpg')) {
      type = 'image/jpeg';
    } else if (name.endsWith('.ico')) {
      type = 'image/ico';
    }
  }

  // We cannot handle size as a prop, because image optimization occurs at build time.
  // Workaround, set sizes[]= in next.config.js responsive property of next-optimized-images
  return (
    <>
      {!noRender ? (
        <picture>
          <source srcSet={require(`images/${name}?webp`)} type="image/webp" />
          <source srcSet={require(`images/${name}`)} type={type} />
          <img
            width={width}
            height={height}
            alt={description}
            src={require(`images/${name}`)}
          />
        </picture>
      ) : null}
    </>
  );
};

export default Image;
