import { Button, Row, Col } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { signIn } from 'next-auth/client';

const SignUp = () => {
  return (
    <div id="about" className="container">
      <style jsx>
        {`
          .container {
            padding-top: 24px;
            padding-bottom: 24px;
            padding-left: 24px;
            padding-right: 24px;
            background-color: black;
            text-color: white;
            padding-top: 100px;
            padding-bottom: 100px;
          }
          h2 {
            color: white;
            font-size: 30px;
            font-weight: 900;
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
              'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
              'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
              'Noto Color Emoji';
          }
          h3 {
            color: white;
          }
          span {
            color: #d9d9d9;
            font-size: 18px;
            text-align: justify;
            text-justify: inter-word;
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
              'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
              'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
              'Noto Color Emoji';
          }
        `}
      </style>
      <Row justify="center" gutter={[16, 16]}>
        <Col>
          <h2>
            Ready to solve your question? Click below to create or restart your
            membership.
          </h2>
        </Col>
      </Row>
      <div className="button-container">
        <Row gutter={[16, 16]} justify="center">
          <Col>
            <Button
              icon={<RightOutlined />}
              size="large"
              type="primary"
              onClick={() => signIn()}
            >
              GET STARTED
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default SignUp;
