/* eslint-disable jsx-a11y/accessible-emoji */
import { PostOrPage, PostsOrPages } from '@tryghost/content-api';
import { Row, Col, Typography, Divider } from 'antd';
import Link from 'next/link';

import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

const { Title, Text, Paragraph } = Typography;

const getReadTime = (text: string | null | undefined): string => {
  if (text) {
    const spaceCount = text.split(' ').length - 1;
    const seconds = spaceCount / 4.41;
    const timeAgo = dayjs().subtract(seconds, 'second');
    const readTime = dayjs.duration(dayjs().diff(timeAgo));
    if (readTime.asHours() > 1) {
      return `${Math.ceil(readTime.asHours())} hours`;
    }
    if (readTime.asHours() === 1) {
      return `${Math.ceil(readTime.asHours())} hour`;
    }
    if (readTime.asMinutes() > 1) {
      return `${Math.ceil(readTime.asMinutes())} minutes`;
    }
    if (readTime.asMinutes() === 1) {
      return `${Math.ceil(readTime.asMinutes())} minute`;
    }
    if (readTime.asSeconds() > 1) {
      return `${Math.ceil(readTime.asSeconds())} seconds`;
    }
    return `${Math.ceil(readTime.asSeconds())} second`;
  }
  return '';
};

type Props = {
  posts: PostsOrPages;
};

const RecentQuestions = (props: Props): JSX.Element => {
  const { posts } = props;
  const description =
    'Our network of top Software Engineers from the industry currate community submissions. The most recent submissions are listed below. \n\nWe use community submissions to help connect tech recruiters with top shelf software engineers. Coding Mastered has a deep network of tech recruiters across multiple industries looking to fill job positions from junior to senior developer.';
  const questions = posts?.map((post: PostOrPage) => (
    <Link href={`/questions/${post.slug}`} key={post.id}>
      <a>
        <div className="row-container">
          <Row justify="center">
            <Col span={8}>
              <img
                width="200"
                alt="question thumbnail"
                src="images/video.png"
              />
            </Col>
            <Col span={15} offset={1}>
              <Title level={3}>{post.title}</Title>
              <Row justify="start" gutter={16}>
                <Col className="gutter-row" xs={18} md={10}>
                  <Title id="title" level={5}>
                    {`🕙 Read Time: About ${getReadTime(post.html)}`}
                  </Title>
                </Col>
                <Col className="gutter-row" xs={18} md={8}>
                  <Text id="title">Verified Answer ✅</Text>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </a>
    </Link>
  ));
  return (
    <div className="container">
      <Divider orientation="left">🙋 Recent Answers</Divider>
      <div className="wrapper">
        <Paragraph
          ellipsis={{ rows: 2, expandable: true, symbol: 'read more' }}
        >
          {description}
        </Paragraph>
      </div>
      {questions}
      <style jsx global>
        {`
          .wrapper {
            margin-top: 8px;
            margin-bottom: 32px;
          }
          .row-container {
            padding-top: 16px;
            padding-bottom: 16px;
            transition: transform 0.2s;
          }

          .row-container:hover {
            transform: scale(
              1.02
            ); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
          }
          .container {
            justify-content: center;
            align-content: center;
            align-items: center;
            padding: 48px 16px;
          }
          .left {
            text-align: start;
          }
          .right {
            text-align: end;
          }

          @media screen and (max-width: 600px) {
            .container {
              padding-right: 4px;
            }
          }

          @media screen and (min-width: 600.00000001px) and (max-width: 1024px) {
            .row-container {
              padding-left: 16px;
            }
          }

          @media screen and (min-width: 1024.00000001px) {
            .row-container {
              padding-left: 16px;
            }
          }
        `}
      </style>
    </div>
  );
};

export default RecentQuestions;
