import { Typography, Row, Col } from 'antd';
import { NextPage } from 'next';
import Image from './Image';

const { Title, Paragraph } = Typography;

const WhySection: NextPage = () => {
  return (
    <div className="container" style={{ overflow: 'hidden' }}>
      <style jsx>
        {`
          .container {
            background-color: #f4f4f4;
            padding-top: 100px;
            padding-bottom: 100px;
          }
          .first-row {
            margin-bottom: 0px;
          }
          h2 {
            font-size: 38px;
            font-weight: 900;
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
              'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
              'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
              'Noto Color Emoji';
          }
          .title {
            text-align: center;
            margin-bottom: 40px;
          }
        `}
      </style>
      <Row justify="center">
        <Col>
          <div className="title">
            <Title>Trusted Mentors</Title>
          </div>
        </Col>
      </Row>
      <Row justify="center">
        <Col xs={20} sm={20} md={12} lg={10}>
          <Row justify="start">
            <Col>
              <Row gutter={[24, 0]} justify="start">
                <Col>
                  <Image width={42} description="" name="10-years.png" />
                </Col>
                <Col>
                  <Title level={3}>10 Years Experience</Title>
                </Col>
              </Row>
              <Paragraph>
                The median amount of experience of our mentor staff is 10 years
                with languages such as JavaScript, React, HTML, CSS, Java,
                Kotlin, Docker, &amp; TypeScript.
              </Paragraph>
            </Col>
          </Row>
          <Row justify="start">
            <Col>
              <Row gutter={[24, 0]} justify="start">
                <Col>
                  <Image width={42} description="" name="UIUC.png" />
                </Col>
                <Col>
                  <Title level={3}>University Certified</Title>
                </Col>
              </Row>
              <Paragraph>
                Our mentor staff has graduated from top 5 Computer Science
                Undergrad program &amp; love helping answer your coding
                questions.
              </Paragraph>
            </Col>
            <Col>
              <Row gutter={[24, 0]} justify="start">
                <Col>
                  <Image width={42} description="" name="open-source.png" />
                </Col>
                <Col>
                  <Title level={3}>Open Source</Title>
                </Col>
              </Row>
              <Paragraph>
                All our solutions are free to copy &amp; use as you please.
                Extend upon our source code to build your next side project for
                a coding interview.
              </Paragraph>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
export default WhySection;
