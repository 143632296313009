import Head from 'next/head';
import { NextPage, GetStaticProps } from 'next';
import { Layout } from 'antd';
import { PostsOrPages } from '@tryghost/content-api';
import GreetingSection from '../components/GreetingSection';
import AboutSection from '../components/AboutSection';
import BrandedFooter from '../components/BrandedFooter';
import Toolbar from '../components/Toolbar';
import QualificationsSection from '../components/QualificationsSection';
import WhySection from '../components/WhySection';
import SignUp from '../components/SignUp';
import RecentQuestions from '../components/RecentQuestions';
import { getQuestions } from '../lib/ghost';

const { Content, Footer } = Layout;

interface Props {
  posts: PostsOrPages;
}

export const getStaticProps: GetStaticProps<Props> = async () => {
  const posts: PostsOrPages = await getQuestions();
  return {
    props: {
      posts,
    },
  };
};

const Index: NextPage<Props> = (props) => {
  const { posts } = props;
  return (
    <Layout>
      <Head>
        <title>Coding Mastered</title>
      </Head>
      <Content style={{ padding: '0px 0px' }}>
        <Toolbar />
        <GreetingSection />
        <RecentQuestions posts={posts} />
        <AboutSection />
        <WhySection />
        <QualificationsSection />
        <SignUp />
      </Content>
      <Footer style={{ padding: 0, margin: 0 }}>
        <BrandedFooter />
      </Footer>
    </Layout>
  );
};

export default Index;
