/* eslint-disable import/no-unresolved */
/* eslint-disable global-require */
import { PageHeader, Button } from 'antd';
import { NextPage } from 'next';
import { signIn, signOut, useSession } from 'next-auth/client';

const Toolbar: NextPage = () => {
  const [session, loading] = useSession();

  const extras = [];
  if (session) {
    extras.push(
      <Button key="5" type="link" size="large" href="/profile/">
        Signed in as
        {` ${session.user.email}`}
      </Button>
    );
  } else {
    extras.push(
      <Button key="5" type="link" size="large" onClick={() => signIn()}>
        Sign In / Sign Up
      </Button>
    );
  }
  return (
    <div>
      <PageHeader title="Coding Mastered" subTitle="" extra={extras} />
    </div>
  );
};
export default Toolbar;
