/* eslint-disable global-require */
import { Typography, Button, Col, Row, Space } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { useState } from 'react';
import Typing from 'react-typing-animation';
import { NextPage } from 'next';
import { signIn } from 'next-auth/client';

const { Title } = Typography;

const Toolbar: NextPage = () => {
  const skills: string[] = [
    'Coding Answers',
    'Trusted Mentors',
    'Getting Hired',
    'CSS & HTML',
  ];

  const [index, setIndex] = useState(0);
  const [skill, setSkill] = useState(skills[0]);

  const typingDone = () => {
    let updatedValue = index + 1;
    if (updatedValue >= skills.length) {
      updatedValue = 0;
    }
    setIndex(updatedValue);
    setSkill(skills[updatedValue]);
  };

  return (
    <div className="container" style={{ overflow: 'hidden' }}>
      <style jsx>
        {`
          .container {
            margin-top: 160px;
            text-align: center;
            padding-bottom: 96px;
          }
          .title {
            margin-botton: 0px;
            padding-bottom: 0px;
          }
          .input-container {
            margin-top: 48px;
          }
          .button-container {
            margin-top: 16px;
          }
          .typing-container {
            height: 50px;
          }
          .clients {
            padding-top: 64px;
          }
        `}
      </style>

      <Space>
        {/* <Image width={500} name={logo} description="" /> */}
        <Title>Coding Mastered</Title>
      </Space>
      <Title level={3}>The One Stop For</Title>
      <div className="typing-container">
        <Typing
          speed={60}
          onFinishedTyping={() => typingDone()}
          startDelay={500}
          loop
        >
          <Title>{skill}</Title>
          <Typing.Backspace delay={2000} count={skill.length} />
        </Typing>
      </div>
      {/* <div className="input-container">
        <Row justify="center">
          <Col span={12}>
            <Input
              size="large"
              placeholder="Email address"
              prefix={<MailOutlined />}
            />
          </Col>
        </Row>
      </div> */}
      <div className="button-container">
        <Row gutter={[16, 16]} justify="center">
          <Col>
            <Button
              icon={<RightOutlined />}
              size="large"
              type="primary"
              onClick={() => signIn()}
            >
              TRY 30 DAYS FREE
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default Toolbar;
