import { NextPage } from 'next';
import { Row, Col } from 'antd';
import Image from './Image';

const AboutSection: NextPage = () => {
  return (
    <div id="about" className="container">
      <style jsx>
        {`
          .container {
            padding-top: 24px;
            padding-bottom: 24px;
            padding-left: 24px;
            padding-right: 24px;
            background-color: black;
            text-color: white;
            padding-top: 100px;
            padding-bottom: 100px;
          }
          h2 {
            color: white;
            font-size: 38px;
            font-weight: 900;
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
              'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
              'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
              'Noto Color Emoji';
          }
          h3 {
            color: white;
          }
          span {
            color: #d9d9d9;
            font-size: 18px;
            text-align: justify;
            text-justify: inter-word;
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
              'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
              'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
              'Noto Color Emoji';
          }
        `}
      </style>
      <Row justify="center" gutter={[16, 16]}>
        <Col>
          <Row justify="start" gutter={[16, 16]}>
            <Col>
              <h2>No question is too big</h2>
            </Col>
          </Row>
          <span>
            We give you all your answers. We have certified mentors from the
            Software Industry ready to answer your questions, no matter how
            complex.
          </span>
          <br />
          <br />
          <Row justify="start" gutter={[16, 16]}>
            <Col>
              <h3>We believe in education and well-being for all.</h3>
            </Col>
          </Row>
          <span>
            That is why we offer the most generous free trial period in the
            sector. What are you waiting for? Get started today!
          </span>
        </Col>
        <Col>
          <br />
          <br />
          <Row justify="center" gutter={[16, 16]}>
            <Col>
              <Image
                width={250}
                description="large"
                name="industry-software-engineer.png"
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default AboutSection;
